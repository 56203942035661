<template>
  <Page title="myGymer-Wahl" color="success">
    <router-view></router-view>
  </Page>
</template>

<script>
export default {
  data() {
    return {
      search: '',
    };
  },
};
</script>
